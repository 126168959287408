import React, { ReactElement } from "react"

import {
  IconCheck,
  IconCheckboxStaticChecked,
  IconCheckboxStaticIndeterminate,
  IconCheckboxStaticUnchecked,
  IconHelp,
} from "@supernovaio/icons"
import { IconType } from "@supernovaio/icons/types"

import { DMIcon } from "../DMIcon"
import { DMTokenPreviewSwatch } from "../DMTokenPreview"
import { DMTooltip } from "../DMTooltip"

import { DMListboxTreeItemStructure } from "./DMListboxTree/types"

export function getGridColumnClass(columns: number): string {
  switch (columns) {
    case 1:
      return "grid-cols-1"
    case 2:
      return "grid-cols-2"
    case 3:
      return "grid-cols-3"
    case 4:
      return "grid-cols-4"
    case 5:
      return "grid-cols-5"
    case 6:
      return "grid-cols-6"
    case 7:
      return "grid-cols-7"
    case 8:
      return "grid-cols-8"
    default:
      return ""
  }
}

export function ListboxCheckmarkIcon({
  isSelected,
  isMultiselect,
  isIndeterminate,
  hasAllChildrenSelected,
  isSelectable,
  hideDisabledHint,
  disabledHint,
}: {
  isSelected: boolean | undefined
  isMultiselect: boolean | undefined
  isIndeterminate?: boolean
  hasAllChildrenSelected?: boolean
  isSelectable?: boolean
  hideDisabledHint?: boolean
  disabledHint?: string
}): JSX.Element | null {
  if (!isSelected && !isMultiselect) {
    return <div className="w-icon-small h-icon-small" />
  }

  let icon: IconType
  let color: "primary" | "neutral-faded" = "primary"

  if (
    isMultiselect &&
    (isSelected || (hasAllChildrenSelected && !isSelectable))
  ) {
    icon = IconCheckboxStaticChecked
  } else if (!isMultiselect && isSelected) {
    icon = IconCheck
  } else if (
    (hasAllChildrenSelected && isMultiselect) ||
    (isIndeterminate && isMultiselect)
  ) {
    icon = IconCheckboxStaticIndeterminate
  } else if (isMultiselect) {
    icon = IconCheckboxStaticUnchecked
    color = "neutral-faded"
  } else {
    icon = IconHelp
    color = "neutral-faded"
  }

  const isDisabled = isSelectable || hideDisabledHint

  return (
    <DMTooltip
      isHidden={isDisabled}
      text={disabledHint ?? "Only the children of this item can be selected"}
      className={!isDisabled ? "pointer-events-auto" : undefined}
    >
      <DMIcon
        size="small"
        color={color}
        svg={icon}
        className={!isSelectable ? "opacity-[0.3]" : undefined}
      />
    </DMTooltip>
  )
}

export function checkSelectionLimit(
  value: string,
  limit: number | undefined,
  selectedValues: string[] | string
): boolean {
  if (limit === undefined) {
    return false
  }

  // Check if the value is part of the selection — if yes, we allow it to be unselected
  const isNotSelectedYet = !selectedValues.includes(value)

  // Check if the count of items in selectedValue is less than the limit
  const isBelowLimit = selectedValues.length < limit

  return isNotSelectedYet && !isBelowLimit
}

export const searchTree = (
  items: DMListboxTreeItemStructure[],
  searchTerm: string
) => {
  if (!searchTerm) {
    return items // Return all items if no search term is provided
  }

  const term = searchTerm.toLowerCase()

  const filter = (
    items: DMListboxTreeItemStructure[]
  ): DMListboxTreeItemStructure[] => {
    const result: DMListboxTreeItemStructure[] = []
    for (let i = 0; i < items.length; i += 1) {
      const item = items[i]
      if (!item) {
        // eslint-disable-next-line no-continue
        continue
      }

      const children = item.children ? filter(item.children) : []
      const nameMatch = item.name.toLowerCase().includes(term)
      const keywordMatch =
        item.keywords &&
        item.keywords.some((keyword) => keyword.toLowerCase().includes(term))

      if (nameMatch || keywordMatch || children.length) {
        result.push({
          ...item,
          children: children.length ? children : undefined,
        })
      }
    }
    return result
  }

  return filter(items)
}

/*
 * Transforms grouped data into a flat array of items, group counts and groups, because that's what virtuoso library needs to work properly with groups
 * https://virtuoso.dev/grouped-by-first-letter/
 */
export const transformGroupDataForVirtualization = (
  data: ListGroup[] | GridGroup[]
) => {
  const items: object[] = []
  const groupCounts: number[] = []
  const groups: object[] = []

  data.forEach((group) => {
    // Adding group name to groups array
    groups.push(group)

    // Counting items in each group and adding to groupCounts
    groupCounts.push(group.items.length)

    // Adding items to the items array
    items.push(...group.items)
  })

  return { items, groupCounts, groups }
}

/*
 * For stories
 */
export type Item = {
  id: string
  name: string
  persistentId?: string
  icon?: IconType | ReactElement
  keywords?: string[]
}
export type ListGroup = {
  id: string
  name: string
  items: Item[]
}

export type GridGroup = {
  id: string
  name: string
  items: ItemRowForGrid[]
}

export type ItemRowForGrid = { rowIndex: number; items: Item[] }
function isListGroup(group: GridGroup | ListGroup): group is ListGroup {
  return (
    Array.isArray((group as ListGroup).items) && // Check if items is an array
    (group as ListGroup).items.length > 0 && // Check if items is not empty
    (group as ListGroup).items[0] !== undefined
  )
}

// Search function for filtering grouped items
export const filterGroupedItems = (
  groupedItems: Array<GridGroup | ListGroup>,
  searchValue: string
) => {
  if (searchValue !== "") {
    const searchText = searchValue.trim().toLowerCase()
    return groupedItems
      .map((group) => {
        // Use the type guard here
        if (isListGroup(group)) {
          return {
            ...group,
            items: group.items.filter(
              (item) =>
                item.name.toLowerCase().includes(searchText) ||
                (item.keywords &&
                  item.keywords.some((keyword) =>
                    keyword.toLowerCase().includes(searchText)
                  ))
            ),
          }
        }
        // Handle the case for GridGroup or any other type
        return group
      })
      .filter((group) => "items" in group && group.items.length > 0)
  }
  return groupedItems
}

function generateRandomHexCode(): string {
  const characters = "0123456789ABCDEF"
  let hexCode = "#"

  let i = 0
  while (i < 6) {
    hexCode += characters[Math.floor(Math.random() * characters.length)]
    i += 1
  }

  return hexCode
}

function ColorIcon({ hexCode }: { hexCode: string }): JSX.Element {
  return (
    <DMTokenPreviewSwatch
      resolvedStyle={{ background: hexCode }}
      token={{
        tokenType: "color",
        displayValue: hexCode,
      }}
    />
  )
}

export const generateArrayOfItems = (count = 20): Item[] => {
  const newArray = []
  const length = count

  let i = 1
  while (i <= length) {
    newArray.push({
      id: `item-${i}`,
      name: `Item ${i}`,
      icon: <ColorIcon hexCode={generateRandomHexCode()} />,
    })
    i += 1
  }

  return newArray
}

export const generateGroupedItems = (
  groupCount = 3,
  itemCount = 10
): ListGroup[] => {
  const nestedObject = []

  let groupId = 1
  while (groupId <= groupCount) {
    const group: ListGroup = {
      id: `group-${groupId}`,
      name: `Group ${groupId}`,
      items: [],
    }

    const itemCountInRange =
      Math.floor(Math.random() * (itemCount - itemCount / 2 + 1)) +
      itemCount / 2

    let itemId = 1
    while (itemId <= itemCountInRange) {
      group.items.push({
        id: `item-${groupId}-${itemId}`,
        name: `Item ${groupId}-${itemId}`,
        icon: <ColorIcon hexCode={generateRandomHexCode()} />,
      })
      itemId += 1
    }

    nestedObject.push(group)
    groupId += 1
  }

  return nestedObject
}

export const chunkItemsToGridLayout = (
  items: Item[],
  gridColumns = 1,
  cache: { rowIndex: number; items: Item[] }[] = [],
  startIndex = 0
): ItemRowForGrid[] => {
  const tmp: Item[] = [...items]
  if (gridColumns <= 0) return cache
  while (tmp.length) {
    cache.push({ rowIndex: startIndex, items: tmp.splice(0, gridColumns) })
    startIndex += 1
  }
  return cache
}
