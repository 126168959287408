import React from "react"

import {
  TextAreaBase,
  TextAreaBaseProps,
} from "@supernovaio/dm/src/components/primitives"
import { cn } from "@supernovaio/dm/src/utils/cn"

import RefObjectExtractor from "../primitives/RefObjectExtractor/RefObjectExtractor"
import SelectOnFocusInputWrapper from "../primitives/SelectOnFocusInputWrapper/SelectOnFocusInputWrapper"

import { cva, VariantProps } from "class-variance-authority"

const inputVariants = cva(["text-body"], {
  variants: {
    /**
     * @deprecated This is a quick workaround before we have DMTextarea with autogrow option
     */
    variant: {
      default: "bg-elevation-base focus:bg-elevation-base p-4 -mx-4 rounded",
      ghost: "bg-transparent focus:bg-transparent p-4 -mx-4 rounded",
      outline:
        "bg-elevation-base inner-border inner-border-neutral-faded transition-colors min-h-form-medium p-8 rounded", // This should be probably bg-transparent but it would need changing variant in components used across the app
    },
    isReadOnly: {
      true: "",
      false: "",
    },
  },
  compoundVariants: [
    {
      variant: "default",
      isReadOnly: false,
      className: "hover:bg-neutral focus:ring-2 focus:ring-offset-0",
    },
    {
      variant: "ghost",
      isReadOnly: false,
      className: "hover:bg-neutral",
    },
    {
      variant: "outline",
      isReadOnly: false,
      className:
        "hover:inner-border-neutral focus:inner-border focus:inner-border-focus focus:ring-2 focus:ring-offset-1",
    },
  ],
  defaultVariants: {
    variant: "default",
    isReadOnly: false,
  },
})

export type DMInlineTextEditProps = Pick<
  TextAreaBaseProps,
  | "id"
  | "allowLineBreaks"
  | "placeholder"
  | "value"
  | "defaultValue"
  | "onChange"
  | "onFocus"
  | "onBlur"
  | "name"
  | "className"
  | "maxLength"
  | "maxRows"
  | "minRows"
  | "onKeyUp"
  | "autoFocus"
  | "onPointerDown"
  | "onKeyDown"
  | "onClick"
  | "cacheMeasurements"
> & {
  isReadOnly?: TextAreaBaseProps["readOnly"]
  selectOnFocus?: boolean
} & VariantProps<typeof inputVariants>

/**
 * @todo It needs to be reviewed, it's using deprecated TextAreaBase component.
 */
const DMInlineTextEdit = React.forwardRef<
  HTMLTextAreaElement,
  DMInlineTextEditProps
>(
  (
    {
      className,
      isReadOnly,
      variant = "default",
      selectOnFocus = true,
      ...props
    },
    ref
  ) => {
    return (
      <RefObjectExtractor<HTMLTextAreaElement>
        forwardedRef={ref}
        render={({ refObject, actualForwardedRef }) => (
          <SelectOnFocusInputWrapper
            inputRefObject={refObject}
            render={({ onFocus }) => (
              <TextAreaBase
                ref={actualForwardedRef}
                className={cn(
                  inputVariants({ variant, isReadOnly }),
                  className
                )}
                readOnly={isReadOnly}
                tabIndex={isReadOnly ? -1 : undefined}
                type="text"
                onFocus={(e) => {
                  e.stopPropagation()
                  onFocus()
                }}
                {...props}
              />
            )}
            selectOnFocus={!isReadOnly && selectOnFocus}
          />
        )}
      />
    )
  }
)

DMInlineTextEdit.displayName = "DMInlineTextEdit"

export { DMInlineTextEdit }
