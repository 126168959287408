import * as React from "react"
import { useCallback, useEffect, useRef, useState } from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import { DMIcon, IconProps } from "../DMIcon"
import { DMLink, DMLinkProps } from "../DMLink"

import { cva, type VariantProps } from "class-variance-authority"

type DMAlertPropsBase = {
  icon?: IconProps["svg"]
  /**
   * Custom color for the icon. If not provided, the icon color will be determined based on the alert color.
   */
  iconColor?: IconProps["color"]
  actionsSlot?: Omit<DMLinkProps, "variant" | "isUnderline">[] | React.ReactNode
  children?: React.ReactNode
} & VariantProps<typeof alertVariants>

type DMAlertProps = DMAlertPropsBase &
  (
    | { isInline?: true; title?: never }
    | { isInline?: false; title: React.ReactNode }
  )

const alertVariants = cva("w-full rounded border", {
  variants: {
    color: {
      neutral: "bg-neutral-faded border-neutral-faded text-neutral",
      primary: "bg-primary-faded border-primary-faded text-primary",
      critical: "bg-critical-faded border-critical-faded text-critical",
      positive: "bg-positive-faded border-positive-faded text-positive",
      warning: "bg-warning-faded border-warning-faded text-warning",
    },
    size: {
      small: "p-12 text-body-small",
      medium: "p-16 text-body",
    },
  },
  defaultVariants: {
    color: "neutral",
    size: "medium",
  },
})

const Alert = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>
>(({ className, color, size, ...props }, ref) => (
  <div
    ref={ref}
    role="alert"
    className={cn(alertVariants({ color, size }), className)}
    {...props}
  />
))
Alert.displayName = "Alert"

const AlertTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, children, ...props }, ref) => (
  <div ref={ref} className={cn("font-semibold", className)} {...props}>
    {children}
  </div>
))
AlertTitle.displayName = "AlertTitle"

type AlertDescriptionProps = React.HTMLAttributes<HTMLParagraphElement> & {
  isInline?: boolean
}

const textColorMapping = {
  neutral: "text-neutral-faded",
  primary: "text-primary-faded",
  critical: "text-critical-faded",
  positive: "text-positive-faded",
  warning: "text-warning-faded",
} as const

const AlertDescription = React.forwardRef<
  HTMLParagraphElement,
  AlertDescriptionProps & VariantProps<typeof alertVariants>
>(({ className, isInline, color, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      !isInline && textColorMapping[color as keyof typeof textColorMapping],
      className
    )}
    {...props}
  />
))
AlertDescription.displayName = "AlertDescription"

export function DMAlert({
  color,
  title,
  icon,
  iconColor,
  size,
  isInline,
  actionsSlot,
  children,
}: DMAlertProps) {
  const actualColor = color ?? "neutral"
  const actualSize = size ?? "medium"

  const getIconColor = useCallback(() => {
    if (iconColor) {
      return iconColor
    }

    if (actualColor === "neutral") {
      return "neutral-faded"
    }
    return actualColor
  }, [iconColor, actualColor])

  const getActionColor = useCallback(() => {
    if (actualColor === "neutral") {
      return "primary"
    }
    return actualColor
  }, [actualColor])

  const textContainerRef = useRef<HTMLDivElement | null>(null)
  const [isMultiLine, setIsMultiLine] = useState(false)

  useEffect(() => {
    const checkTextLines = () => {
      if (textContainerRef.current) {
        const lineHeight = parseInt(
          window.getComputedStyle(textContainerRef.current).lineHeight,
          10
        )
        const lines = textContainerRef.current.scrollHeight / lineHeight
        setIsMultiLine(lines > 1)
      }
    }

    checkTextLines()
    window.addEventListener("resize", checkTextLines)
    return () => window.removeEventListener("resize", checkTextLines)
  }, [])

  const needsCenterAlign = isInline && !isMultiLine

  const contentSection = (
    <div
      className={cn(
        "flex gap-4",
        isInline ? " flex-row" : "flex-col",
        needsCenterAlign ? "items-center" : "items-start",
        actualSize === "small" && "mt-2" // to align with the icon
      )}
    >
      {!isInline && title && <AlertTitle>{title}</AlertTitle>}
      <AlertDescription
        ref={textContainerRef}
        color={actualColor}
        isInline={isInline}
      >
        {children}
      </AlertDescription>
    </div>
  )

  const actionsSection = (() => {
    const wrapperClasses = cn(
      "flex flex-row gap-12 flex-grow-0 flex-shrink-0 items-start",
      isInline && actualSize === "small" && "mt-2" // to align with the icon
    )

    if (!actionsSlot) {
      return null
    }

    if (!Array.isArray(actionsSlot)) {
      // Plain ReactNode is used, return as is
      return <div className={wrapperClasses}>{actionsSlot}</div>
    }

    if (actionsSlot.length === 0) {
      // No actions
      return null
    }

    // Convert DMLinkProps to DMLink components
    return (
      <div className={wrapperClasses}>
        {actionsSlot.map((actionProps, index) => (
          <DMLink
            key={index}
            {...actionProps}
            variant={getActionColor()}
            size={actualSize}
            isUnderline={false}
          />
        ))}
      </div>
    )
  })()

  return (
    <Alert color={actualColor} size={size}>
      <div
        className={cn(
          "flex flex-row grow gap-12",
          needsCenterAlign ? "items-center" : "items-start"
        )}
      >
        {icon && (
          <div className="flex items-center justify-center h-icon-medium w-icon-medium">
            <DMIcon size="small" svg={icon} color={getIconColor()} />
          </div>
        )}
        <div className="flex flex-col flex-grow flex-shrink gap-8">
          {contentSection}
          {!isInline && actionsSection}
        </div>
        {isInline && actionsSection}
      </div>
    </Alert>
  )
}
